
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
  watch
} from "vue";
import { EarningStatisticsQuery } from "@/components/statistics/index-earnings.vue";
import { useStore } from "vuex";
import ArrowUpIcon from "@/components/Icons/ArrowUp.vue";
import ArrowDownIcon from "@/components/Icons/ArrowDown.vue";

export interface ClientProfitMargin {
  client: string;
  current: number;
  trend: "up" | "down" | null;
  logo?: undefined | string;
  company_id: number;
}

export default defineComponent({
  name: "profit-margin-by-client",
  components: { ArrowDownIcon, ArrowUpIcon },
  props: {
    globalQuery: {
      type: Object as PropType<EarningStatisticsQuery>,
      required: true
    },
    fetchAtMount: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  setup(props) {
    const store = useStore();
    const loading = ref<boolean>(false);

    function setLoading(state: boolean): void {
      loading.value = state;
    }

    const statisticData = computed<ClientProfitMargin[]>(
      () => store.getters["StatisticsModule/marginByClient"]
    );

    const query = reactive({
      startDate: null as string | null,
      endDate: null as string | null
    });

    function fetchStatistic(): void {
      setLoading(true);
      store
        .dispatch("StatisticsModule/fetchMarginByClient", query)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    onMounted((): void => {
      if (props.fetchAtMount) {
        fetchStatistic();
      }
    });

    watch(
      props.globalQuery,
      () => {
        query.startDate = props.globalQuery.dates?.start ?? null;
        query.endDate = props.globalQuery.dates?.end ?? null;
        fetchStatistic();
      },
      { deep: true }
    );

    onUnmounted(() => {
      store.dispatch("StatisticsModule/clearMarginStats");
    });

    return {
      fetchStatistic,
      statisticData,
      loading
    };
  }
});
