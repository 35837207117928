
import type { DateFilterSelectEvent } from "@/components/date-filter/types";
import DateSelector from "@/components/date-filter/DateFilter.vue";
import { defineComponent, reactive, watch } from "vue";

export default defineComponent({
  name: "statistics-filter-tab",
  components: { DateSelector },
  emits: ["filter-changed"],
  setup: function(props, { emit }) {
    const filter = reactive({
      dates: null as null | DateFilterSelectEvent
    });

    function handleDateSelect(data: DateFilterSelectEvent | null): void {
      filter.dates = data;
    }

    watch(filter, () => {
      emit("filter-changed", filter);
    }, { deep: true}
  );


    return {
      handleDateSelect,
      filter
    };
  }
});
