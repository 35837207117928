<template>
  <div class="mt-8" :key="key">
    <apexchart
      type="area"
      height="350"
      :options="chart.options"
      :series="chart.data"
    ></apexchart>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, watch } from "vue";
import { formatCurrency } from "@/core/helpers/CurrencyFormat";

export default defineComponent({
  name: "revenue-line-chart",
  props: {
    data: {
      type: Object
    }
  },
  setup: function(props) {
    const key = ref(0);

    const chart = reactive({
      data: [
        {
          name: "Earnings",
          data: []
        }
      ],
      options: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        noData: {
          text: "No data..."
        },
        xaxis: {
          type: "category",
          categories: []
        },
        tooltip: {
          x: {
            format: "MMM yyyy"
          },
          y: {
            enabled: false,
            title: {
              formatter: () => ""
            },
            formatter: (val, data) => {
              return data === undefined
                ? val
                : formatCurrency(val, data.seriesIndex == 0 ? "EUR" : "DKK");
            }
          }
        }
      }
    });

    const setData = () => {
      if (props.data) {
        chart.options.xaxis.categories = props.data.categories;
        chart.data = props.data.data;
      } else {
        chart.options.xaxis.categories = chart.data = [];
      }
      key.value++;
    };

    watch(
      () => props.data,
      () => {
        setData();
      },
      { deep: true }
    );

    return {
      chart,
      key
    };
  }
});
</script>
