
import { ActionRowSetup } from "@/components/date-filter/types";
import { defineComponent, ref } from "vue";
import moment from "moment";

export default defineComponent({
  name: "years-tab",
  computed: {
    ActionRowSetup() {
      return ActionRowSetup;
    }
  },
  emits: ["select"],
  setup: function(props, { emit }) {
    const datePicker = ref();
    const pickedDate = ref();
    const maxYear = moment()
      .add(12, "years")
      .year();

    function handleSelectDate(event: Date[]): void {
      if (event && event.length == 2) {
        emit("select", {
          start: moment(event[0]).startOf("year"),
          end: moment(event[1]).endOf("year")
        });
      }
    }

    return {
      handleSelectDate,
      datePicker,
      pickedDate,
      maxYear
    };
  }
});
