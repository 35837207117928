
import { ActionRowSetup } from "@/components/date-filter/types";
import { defineComponent, ref, watch } from "vue";
import moment from "moment";
export default defineComponent({
  name: "weeks-tab",
  computed: {
    ActionRowSetup() {
      return ActionRowSetup;
    }
  },
  emits: ["select"],
  setup: function(props, { emit }) {
    const pickedDate = ref();

    function handleSelectDate(event: Date[]): void {
      if (event && event.length == 2) {
        emit("select", {
          start: moment(event[0]),
          end: moment(event[1])
        });
      }
    }

    watch(
      () => pickedDate.value,
      () => {
        console.log(pickedDate.value);
      },
      { deep: true }
    );

    return {
      handleSelectDate,
      pickedDate
    };
  }
});
