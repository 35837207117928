
import { isValidRange, RangeTabModes } from "@/components/date-filter/RangeTabModes";
import { defineComponent, PropType, ref } from "vue";
import moment from "moment";

export default defineComponent({
  name: "range-tab",
  computed: {
    RangeTabModes() {
      return RangeTabModes;
    }
  },
  emits: ["select"],
  setup: function(props, { emit }) {
    const radioButtonMode = ref<number>();
    const customRange = ref<boolean>(false);
    const rangeModel = ref<Date | null>(null);

    function handleCustomRange(event: Date[]): void {
      emit("select", {
        start: moment(event[0]),
        end: moment(event[1])
      });
    }

    function handleChange(): void {
      const now = moment();
      customRange.value = false;
      switch (radioButtonMode.value) {
        case RangeTabModes.ONE_WEEK:
          emit("select", {
            start: now.subtract(1, "week"),
            end: moment()
          });
          break;
        case RangeTabModes.ONE_MONTH:
          emit("select", {
            start: now.subtract(1, "month"),
            end: moment()
          });
          break;
        case RangeTabModes.THREE_MONTHS:
          emit("select", {
            start: now.subtract(3, "months"),
            end: moment()
          });
          break;
        case RangeTabModes.TWELVE_MONTHS:
          emit("select", {
            start: now.subtract(12, "months"),
            end: moment()
          });
          break;
        case RangeTabModes.CUSTOM_RANGE:
          rangeModel.value = null;
          customRange.value = true;
          break;
        case RangeTabModes.TODAY:
          emit("select", {
            start: moment().startOf("day"),
            end: moment().endOf("day")
          });
          break;
        case RangeTabModes.THIS_WEEK:
          emit("select", {
            start: moment().startOf("week"),
            end: moment().endOf("week")
          });
          break;
        case RangeTabModes.THIS_MONTH:
          emit("select", {
            start: moment().startOf("month"),
            end: moment().endOf("month")
          });
          break;
        case RangeTabModes.THIS_YEAR:
          emit("select", {
            start: moment().startOf("year"),
            end: moment().endOf("year")
          });
          break;
      }
    }

    function setDefaultValue(value: RangeTabModes): void {
      if (isValidRange(value)) {
        radioButtonMode.value = value;
        handleChange();
      }
    }

    return {
      handleCustomRange,
      setDefaultValue,
      radioButtonMode,
      handleChange,
      customRange,
      rangeModel
    };
  }
});
