
import { ActionRowSetup } from "@/components/date-filter/types";
import { defineComponent, ref } from "vue";
import moment from "moment/moment";

export default defineComponent({
  name: "months-tab",
  computed: {
    ActionRowSetup() {
      return ActionRowSetup;
    }
  },
  emits: ["select"],
  setup: function(props, { emit }) {
    const pickedDate = ref();

    function handleSelectDate(event: Date): void {
      if (event) {
        const month = moment(event);
        const startOfMonth = month.clone().startOf("month");
        const endOfMonth = month.clone().endOf("month");
        emit("select", {
          start: startOfMonth,
          end: endOfMonth
        });
      }
    }

    return {
      handleSelectDate,
      pickedDate
    };
  }
});
