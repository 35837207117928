
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch
} from "vue";
import RevenueLineChart from "@/components/statistics/earnings-charts/RevenueLineChart.vue";
import { EarningStatisticsQuery } from "@/components/statistics/index-earnings.vue";
import BranchesDropdown from "@/components/dropdown/BranchesDropdown.vue";
import { useStore } from "vuex";

interface EarningsStatisticQueryParams {
  startDate: string | null;
  endDate: string | null;
  branchId: number | null;
}

export default defineComponent({
  name: "revenue-chart-index",
  components: { BranchesDropdown, RevenueLineChart },
  props: {
    globalQuery: {
      type: Object as PropType<EarningStatisticsQuery>,
      required: true
    },
    fetchAtMount: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  setup: function(props) {
    const store = useStore();
    const loading = ref<boolean>(false);
    const query = reactive<EarningsStatisticQueryParams>({
      startDate: null,
      endDate: null,
      branchId: null
    });

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    function fetchStatistics(): void {
      setLoading(true);
      store
        .dispatch("StatisticsModule/fetchEarningStatistics", query)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    onMounted((): void => {
      if (props.fetchAtMount) {
        fetchStatistics();
      }
    });

    const statistics = computed(
      () => store.getters["StatisticsModule/earnings"]
    );

    watch(
      () => props.globalQuery.dates,
      () => {
        query.startDate = props.globalQuery.dates
          ? props.globalQuery.dates.start
          : null;
        query.endDate = props.globalQuery.dates
          ? props.globalQuery.dates.end
          : null;
      },
      { deep: true }
    );

    watch(
      query,
      () => {
        fetchStatistics();
      },
      { deep: true }
    );

    return {
      statistics,
      query
    };
  }
});
