
import ConsolidatedExpensesChart from "@/components/statistics/charts/ConsolidatedExpensesChart.vue";
import ClientRevenueChart from "@/components/statistics/earnings-charts/ClientRevenueChart.vue";
import RevenueChartIndex from "@/components/statistics/charts/RevenueChart.vue";
import StatisticsFilterTab from "@/components/statistics/misc/FiltersTab.vue";
import { DateFilterSelectEvent } from "@/components/date-filter/types";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, ref } from "vue";
import ProfitMarginByClient from "@/components/statistics/earnings-charts/ProfitMargin.vue";

export interface EarningStatisticsQuery {
  dates: null | DateFilterSelectEvent;
}

export default defineComponent({
  name: "earnings-statistics-index",
  components: {
    ProfitMarginByClient,
    ClientRevenueChart,
    StatisticsFilterTab,
    ConsolidatedExpensesChart,
    RevenueChartIndex
  },
  setup: function() {
    const query = ref<EarningStatisticsQuery>({
      dates: null
    });

    onMounted(() => {
      setCurrentPageTitle("Earnings statistics");
    });

    function handleChangeQuery(payload): void {
      Object.assign(query.value, payload);
    }

    return {
      handleChangeQuery,
      query
    };
  }
});
