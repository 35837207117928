import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex w-100 justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueDatePicker, {
      "week-picker": "",
      "action-row": _ctx.ActionRowSetup,
      disableYearSelect: true,
      "menu-class-name": "dp-custom-menu",
      "enable-time-picker": false,
      inline: true,
      range: "",
      onInternalModelChange: _ctx.handleSelectDate,
      "model-value": _ctx.pickedDate
    }, null, 8, ["action-row", "onInternalModelChange", "model-value"]),
    _createTextVNode(" " + _toDisplayString(_ctx.pickedDate), 1)
  ]))
}