
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
  watch
} from "vue";
import { useStore } from "vuex";
import ExpensesDonutChart, {
  ExpensesData
} from "@/components/statistics/earnings-charts/ExpensesDonutChart.vue";
import { EarningStatisticsQuery } from "@/components/statistics/index-earnings.vue";
import InfoPopup from "@/components/general/InfoPopup.vue";
export default defineComponent({
  name: "consolidated-expenses-chart",
  components: { InfoPopup, ExpensesDonutChart },
  props: {
    globalQuery: {
      type: Object as PropType<EarningStatisticsQuery>,
      required: true
    },
    fetchAtMount: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  setup: function(props) {
    const store = useStore();
    const loading = ref<boolean>(false);

    const query = reactive({
      start: null as null | string,
      end: null as null | string
    });

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    function fetchChart(): void {
      setLoading(true);
      store
        .dispatch("StatisticsModule/fetchConsolidatedExpenses", query)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    const data = computed<ExpensesData>(
      (): ExpensesData => store.getters["StatisticsModule/consolidatedExpenses"]
    );

    onMounted((): void => {
      if (props.fetchAtMount) {
        fetchChart();
      }
    });

    onUnmounted(() => {
      store.dispatch("StatisticsModule/clearConsolidatedExpenses");
    });

    watch(
      () => props.globalQuery.dates,
      () => {
        query.start = props.globalQuery.dates
          ? props.globalQuery.dates.start
          : null;
        query.end = props.globalQuery.dates
          ? props.globalQuery.dates.end
          : null;
      },
      { deep: true }
    );

    watch(
      () => query,
      () => {
        fetchChart();
      },
      { deep: true }
    );

    return {
      loading,
      data
    };
  }
});
