
import { defineComponent, PropType } from "vue";
import CircleInfo from "@/components/Icons/CircleInfo.vue";
export default defineComponent({
  name: "info-popup",
  components: { CircleInfo },
  props: {
    text: {
      type: String as PropType<string>,
      default: () => "Default content"
    }
  },
  setup: function() {
    return {};
  }
});
