import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 d-flex flex-column" }
const _hoisted_2 = { class: "d-flex flex-column flex-xl-row" }
const _hoisted_3 = { class: "d-flex w-100 mt-5 flex-column flex-xl-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsFilterTab = _resolveComponent("StatisticsFilterTab")!
  const _component_RevenueChartIndex = _resolveComponent("RevenueChartIndex")!
  const _component_ConsolidatedExpensesChart = _resolveComponent("ConsolidatedExpensesChart")!
  const _component_ClientRevenueChart = _resolveComponent("ClientRevenueChart")!
  const _component_ProfitMarginByClient = _resolveComponent("ProfitMarginByClient")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_StatisticsFilterTab, { onFilterChanged: _ctx.handleChangeQuery }, null, 8, ["onFilterChanged"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RevenueChartIndex, {
        "global-query": _ctx.query,
        class: "w-100 mb-5 mb-md-0 me-0 me-xl-3"
      }, null, 8, ["global-query"]),
      _createVNode(_component_ConsolidatedExpensesChart, {
        "global-query": _ctx.query,
        class: "w-100 ms-0 ms-xl-3"
      }, null, 8, ["global-query"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ClientRevenueChart, {
        class: "w-100 me-0 me-xl-3",
        "global-query": _ctx.query
      }, null, 8, ["global-query"]),
      _createVNode(_component_ProfitMarginByClient, {
        class: "w-100 ms-0 ms-xl-3",
        "global-query": _ctx.query
      }, null, 8, ["global-query"])
    ])
  ]))
}