export enum RangeTabModes {
  ONE_WEEK,
  ONE_MONTH,
  THREE_MONTHS,
  TWELVE_MONTHS,
  TODAY,
  THIS_WEEK,
  THIS_MONTH,
  THIS_YEAR,
  CUSTOM_RANGE
}

export function isValidRange(value: number): boolean {
  return Object.values(RangeTabModes).includes(value);
}