import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex w-100 justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueDatePicker, {
      inline: true,
      "menu-class-name": "dp-custom-menu",
      "mode-height": "200",
      "action-row": _ctx.ActionRowSetup,
      "month-picker": "",
      onInternalModelChange: _ctx.handleSelectDate,
      modelValue: _ctx.pickedDate,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pickedDate) = $event))
    }, null, 8, ["action-row", "onInternalModelChange", "modelValue"])
  ]))
}