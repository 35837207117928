
import { EarningStatisticsQuery } from "@/components/statistics/index-earnings.vue";
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  reactive,
  ref,
  watch
} from "vue";
import { useStore } from "vuex";
import InfoPopup from "@/components/general/InfoPopup.vue";
import { formatCurrency } from "@/core/helpers/CurrencyFormat";

export default defineComponent({
  name: "client-revenue-chart",
  components: { InfoPopup },
  props: {
    globalQuery: {
      type: Object as PropType<EarningStatisticsQuery>,
      required: true
    },
    fetchAtMount: {
      type: Boolean as PropType<boolean>,
      default: () => false
    }
  },
  setup: function(props) {
    const store = useStore();
    const loading = ref<boolean>(false);
    const key = ref<number>(0);

    const query = reactive({
      startDate: null as string | null,
      endDate: null as string | null
    });

    function setLoading(state: boolean): void {
      loading.value = state;
    }

    const chartData = computed(
      () => store.getters["StatisticsModule/clientRevenue"]
    );
    const chart = reactive({
      options: {
        tooltip: {
          y: {
            formatter: function(val) {
              return formatCurrency(val, "EUR");
            }
          }
        },
        labels: [] as string[],
        series: [],
        noData: {
          text: "No data..."
        },
        chart: {
          width: 380,
          type: "pie"
        }
      }
    });
    const setData = (): void => {
      Object.assign(chart.options.labels, Object.keys(chartData.value.data));
      chart.options.series = Object.values(chartData.value.data);
    };

    function fetchClientRevenue(): void {
      setLoading(true);
      store
        .dispatch("StatisticsModule/fetchClientRevenueStatistic", query)
        .then(() => {
          if (chartData.value.total == 0) {
            chart.options.series = chart.options.labels = [];
          } else {
            setData();
          }
          key.value++;
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    onMounted((): void => {
      if (props.fetchAtMount) {
        fetchClientRevenue();
      }
    });

    watch(
      props.globalQuery,
      () => {
        query.startDate = props.globalQuery.dates?.start ?? null;
        query.endDate = props.globalQuery.dates?.end ?? null;
        fetchClientRevenue();
      },
      { deep: true }
    );

    return {
      chartData,
      chart,
      key
    };
  }
});
