import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleInfo = _resolveComponent("CircleInfo")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    content: _ctx.text,
    placement: "bottom",
    effect: "light"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CircleInfo, { class: "cursor-pointer" })
    ]),
    _: 1
  }, 8, ["content"]))
}