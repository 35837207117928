
import { defineComponent, PropType, reactive, ref, watch } from "vue";
import { formatCurrency } from "@/core/helpers/CurrencyFormat";
export interface ExpensesData {
  additional_expenses: number;
  salaries: number;
}

export default defineComponent({
  name: "expenses-donut-chart",
  props: {
    data: {
      type: Object as PropType<ExpensesData>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  setup: function(props) {
    const key = ref<number>(0);
    const chart = reactive({
      options: {
        tooltip: {
          y: {
            formatter: function(val) {
              return formatCurrency(val, "EUR");
            }
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  formatter: function(val) {
                    const parsed = parseInt(val);
                    return !isNaN(parsed)
                      ? formatCurrency(parseInt(val), "EUR")
                      : "";
                  }
                }
              }
            }
          }
        },
        labels: [] as string[],
        noData: {
          text: "No data..."
        },
        chart: {
          width: 380,
          type: "donut"
        }
      },
      data: [0, 0]
    });

    watch(
      () => props.loading,
      () => {
        if (!props.loading) {
          const labels: string[] = [];
          const data: number[] = [];

          if (props.data.salaries != 0) {
            labels.push("Salaries");
            data.push(props.data.salaries);
          }

          if (props.data.additional_expenses != 0) {
            labels.push("Additional expenses");
            data.push(props.data.additional_expenses);
          }

          chart.options.labels = labels;
          chart.data = data;

          key.value++;
        }
      }
    );

    return {
      chart,
      key
    };
  }
});
