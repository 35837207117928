
import ElementPlusClearIcon from "@/components/Icons/ElementPlusClearIcon.vue";
import YearsTab from "@/components/date-filter/Tabs/YearsTab.vue";
import RangeTab from "@/components/date-filter/Tabs/RangeTab.vue";
import MonthsTab from "@/components/date-filter/Tabs/MonthsTab.vue";
import WeeksTab from "@/components/date-filter/Tabs/WeeksTab.vue";
import { defineComponent, onMounted, PropType, ref } from "vue";
import { Moment } from "moment";
import { RangeTabModes } from "@/components/date-filter/RangeTabModes";

export interface DateFilterSelectEvent {
  start: Moment;
  end: Moment;
}

export default defineComponent({
  name: "date-selector",
  components: { ElementPlusClearIcon, WeeksTab, MonthsTab, RangeTab, YearsTab },
  emits: ["range-selected", "cleared"],
  props: {
    dateFormat: {
      type: String as PropType<string>,
      default: () => "DD/MM/YYYY"
    },
    defaultValue: {
      type: Number as PropType<number>,
      default: () => RangeTabModes.THIS_YEAR
    }
  },
  setup: function(props, { emit }) {
    const virtualRef = ref();
    const popoverRef = ref();
    const rangeTab = ref<InstanceType<typeof RangeTab>>();
    const tab = ref<string>("Range");
    const value = ref<string>("");
    const popoverKey = ref<number>(0);

    function handleChangeValue(data: DateFilterSelectEvent): void {
      const start = data.start.format(props.dateFormat);
      const end = data.end.format(props.dateFormat);
      emit("range-selected", {
        start: start,
        end: end
      });
      value.value = `${start} - ${end}`;
      popoverRef.value.hide();
    }

    onMounted((): void => {
      rangeTab.value?.setDefaultValue(RangeTabModes.THIS_YEAR);
    });

    function handleClear(event): void {
      event.stopPropagation();
      value.value = "";
      tab.value = "Range";
      popoverKey.value++;
      emit("cleared");
    }

    return {
      handleChangeValue,
      handleClear,
      virtualRef,
      popoverRef,
      popoverKey,
      rangeTab,
      value,
      tab
    };
  }
});
